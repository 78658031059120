<template>
  <div>
    <div class="container-fluid pb-3 pt-5 pt-md-6">
      <div class="row col-12 page-head">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb page-head-nav">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard' }">Home</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'docker-images' }">Docker Images</router-link>
            </li>
            <li class="breadcrumb-item">
              Docker Image Details
            </li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="user-profile col-md-4 mb-3">
          <div class="user-display">
            <div class="user-display-bg" style="border-radius: 50%;">
              <img
                src="/img/docker.png"
                alt="Profile Background"
                style="border-radius: 50%;"
              />
            </div>
          </div>
        </div>
        <div class="col-md-8 mb-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="scroll">
                <h3 class="text-uppercase mb-3">
                  Docker Image Details
                </h3>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{{ dockerImage.name }}</td>
                    </tr>
                    <tr>
                      <th>Common Identifier</th>
                      <td class="">{{ dockerImage.common_identifier }}</td>
                    </tr>
                    <tr>
                      <th>Pull Command</th>
                      <td class="">{{ dockerImage.pull_command }}</td>
                    </tr>
                    <tr>
                      <th>Run Command</th>
                      <td class="">{{ dockerImage.run_command }}</td>
                    </tr>
                    <tr>
                      <th>Service Name</th>
                      <td class="">{{ dockerImage.service_name }}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td class="">{{ dockerImage.image_type }}</td>
                    </tr>
                    <tr>
                      <th>Created</th>
                      <td>{{ new Date(dockerImage.created).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dockerImage: {},
    };
  },
  mounted() {
    this.getDockerImage(this.$route.params.id);
  },
  methods: {
    getDockerImage(id) {
      axios
        .get(`v1/docker-images/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.dockerImage = response.data;
            console.log(this.dockerImage);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
  },
};
</script>

<style></style>
